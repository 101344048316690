<template>
  <article class="appointment-details">
    <div
      v-if="!ui.isLoading || appointment.details === null"
      class="appointment-details__wrapper"
    >
      <div class="mb-8 w-full">
        <div style="max-width: 7rem">
          <router-link
            class="button button--blank !h-3 !text-xs !lowercase sm:ml-4"
            style="height: 0.75rem;"
            :to="{ name: 'UserMedicalHistory' }"
          >
            &lt; Voltar
          </router-link>
        </div>
      </div>

      <div class="appointment-details__details">
        <h2 class="appointment-details__title">
          {{ appointment.details['medical_consultation_type'] }}
          {{ getSpecialty(appointment.details) }}
        </h2>

        <div class="flex flex-row items-center content-start justify-start">
          <h3 class="appointment-details__doctor">
            {{ appointment.details.doctor.user.gender === 'F' ? 'Dra.' : 'Dr.' }}
            {{ appointment.details.doctor.user.name }}
          </h3>

          <div class="appointment-details__modality">
            <span
              :class="
                /telemedicina/gi.test(appointment.details.type)
                  ? 'appointment-card__modality-label--telemedicina'
                  : 'appointment-card__modality-label--presencial'
              "
              class="appointment-card__modality-label"
            >
              {{ appointment.modality }}{{
                /telemedicina/gi.test(appointment.details.type)
                  ? 'Telemedicina'
                  : 'Presencial'
              }}
            </span>
          </div>
        </div>

        <div class="flex flex-row items-center content-start justify-start">
          <p class="appointment-details__crm">
            CRM {{ appointment.details.doctor.crm }} /
            {{ appointment.details.doctor['crm_regional'] }}
          </p>
        </div>

        <div class="mt-4 flex flex-row items-center content-start justify-start">
          <icon
            name="agenda"
            color="primary"
            class="mr-2"
          />

          <div class="appointment-details__date">
            {{ formatDate(appointment.details['appointment_start']) }}
          </div>
        </div>

        <div
          v-if="modality === 'FACETOFACE'"
          class="flex flex-row items-start content-start justify-start mt-4"
        >
          <icon
            name="map"
            color="primary"
            class="mr-2"
            style="height: 2rem"
          />

          <div class="appointment-details__date">
            {{ address }}
          </div>
        </div>

        <div
          v-if="isPast"
          class="mt-4 flex flex-row items-center content-start justify-start"
        >
          <a
            class="button button--blank !h-3 !text-xs !lowercase"
            style="height: 0.75rem;"
            target="_blank"
            :href="calendarEvent"
          >
            Adicionar ao Google Agenda
          </a>

          <a
            v-if="modality === 'FACETOFACE'"
            class="button button--blank ml-3 !h-3 !text-xs !lowercase"
            style="height: 0.75rem;"
            target="_blank"
            :href="`https://maps.google.com/?q=${address}`"
          >
            Traçar Rota
          </a>
        </div>
      </div>

      <div
        v-show="/aberto/gi.test(appointment.details.status)"
        class="appointment-details__status"
      >
        <icon
          name="calendar"
          color="white"
          fill="#4B713D"
          class="m-0 p-0"
          style="height: 3rem"
        />

        <span>agendado</span>
      </div>

      <div class="appointment-details__footer">
        <template v-if="isPast">
          <div
            v-if="showScheduleButton"
            class="mr-auto"
          >
            <ui-button
              :disabled="ui.isLoading"
              label="Reagendar consulta"
              @click="ui.showModalReschedule = true"
            />
          </div>
        </template>

        <div class="ml-auto">
          <ui-button
            v-if="showScheduleButton
              || isAppointmentCancellable(
                appointment.details.status,
                appointment.details['appointment_start'],
              )
            "
            :disabled="ui.isLoading"
            label="Cancelar agendamento"
            color="cancel"
            @click="confirmCancelation"
          />
        </div>
      </div>
    </div>

    <appointment-reschedule
      v-if="ui.showModalReschedule"
      :agenda-id="appointment.details.id"
      :office-id="appointment.details['doctor_office'].id"
      :modality="modality"
      @close="ui.showModalReschedule = false"
      @success="init"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.isLoading" />
    </transition>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  format,
  isBefore,
  parseISO,
  differenceInHours,
} from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import handleError from '@/mixins/handleError';
import validateCancelAppointment from '@/mixins/validateCancelAppointment';
import appointments from '@/api/user/appointments';
import AppointmentReschedule from '@/components/user/appointments/appointmentReschedule.vue';
import Loader from '@/components/ui/Loader.vue';
import Icon from '@/components/ui/Icon.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'UserAppointmentDetails',

  mixins: [handleError, validateCancelAppointment],

  components: {
    AppointmentReschedule,
    Loader,
    Icon,
    UiButton,
  },

  data() {
    return {
      appointment: {
        api: null,
        details: null,
      },
      ui: {
        isLoading: false,
        showModalReschedule: false,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),

    isPast() {
      if (this.appointment.details['appointment_start']) {
        const start = parseISO(this.appointment.details['appointment_start']);

        return isBefore(new Date(), start, { unit: 's' });
      }

      return true;
    },

    showScheduleButton() {
      const appointment = 'appointment_start' in this.appointment.details
        ? parseISO(this.appointment.details['appointment_start'])
        : null;

      if (appointment) {
        const now = new Date();

        return differenceInHours(appointment, now) >= 24;
      }

      return true;
    },

    modality() {
      if (
        this.appointment.details
        && 'type' in this.appointment.details
      ) {
        return /telemedicina/gi.test(this.appointment.details.type)
          ? 'TELEMEDICINE'
          : 'FACETOFACE';
      }

      return '';
    },

    address() {
      if (
        this.appointment.details
        && 'doctor_office' in this.appointment.details
        && 'address' in this.appointment.details['doctor_office']
        && this.appointment.details['doctor_office'].address
      ) {
        const { address } = this.appointment.details['doctor_office'];
        return `${
          address.street
        } - ${
          address.number
        }${
          address.complement ? `, ${address.complement}` : ''
        } CEP ${
          address.cep.replace(/^([\d]{5})([\d]{3})?/g, '$1-$2')
        }, ${
          address.city.name
        } - ${
          address.uf.name
        }`;
      }

      return '';
    },

    calendarEvent() {
      if (this.appointment.details) {
        return `http://www.google.com/calendar/render?action=TEMPLATE&text=${
          this.appointment.details['medical_consultation_type']
        } ${
          this.getSpecialty(this.appointment.details)
        }&dates=${
          this.appointment.details['appointment_start']
        }${
          this.appointment.details['appointment_finish']
        }&details=${
          this.appointment.details['medical_consultation_type']
        } ${
          this.getSpecialty(this.appointment.details)
        }&location=${
          this.address
        }&trp=false&sprop=&sprop=name:`;
      }

      return '';
    },
  },

  watch: {
    '$route.params.id': {
      deep: false,
      immediate: false,
      handler(id) {
        if (id) {
          this.init();
        }
      },
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    init() {
      this.appointment = appointments(this.headers, this.$route.params.id);

      this.ui.isLoading = true;
      this.toggleProgressBar();

      this.appointment
        .get()
        .then((data) => this.$set(this.appointment, 'details', data))
        .catch((err) => {
          this.handleError(err);
          this.$router.push({ name: 'UserAppointments' });
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    formatDate(date) {
      return format(
        parseISO(date),
        'iii\',\' dd/MM \'de\' yyyy \'às\' HH\':\'mm',
        { locale: ptBr },
      );
    },

    confirmCancelation() {
      this.openModalDialog({
        title: 'Cancelar consultar',
        type: 'confirmation',
        text: 'Deseja realmente cancelar esta consulta?<br>Esta ação não poderá ser desfeita.<br><br>',
        cancelText: 'Voltar',
        confirmText: 'Confirmar',
        fn: this.cancelAppointment,
      });
    },

    cancelAppointment() {
      this.ui.isLoading = true;
      this.toggleProgressBar();
      this.closeModalDialog();

      this.appointment
        .cancel()
        .then(() => {
          this.closeModalDialog();
          this.openModalDialog({
            type: 'alert',
            title: 'Sucesso',
            text: 'A consulta foi cancelada com sucesso.',
            confirmText: 'Fechar',
            size: 'sm',
            fn: () => {
              this.closeModalDialog();
              this.$router.push({ name: 'UserAppointments' });
            },
          });
        })
        .catch(() => this.handleError(new Error('Esta consulta não pode ser cancelada.')))
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    getSpecialty(details) {
      if (
        'medical_specialty' in details
        && details['medical_specialty']
      ) {
        return details['medical_specialty'].name;
      }

      if (
        'doctor_office_medical_service' in details
        && details['doctor_office_medical_service']
        && 'medical_service' in details['doctor_office_medical_service']
      ) {
        return details['doctor_office_medical_service']['medical_service'].name;
      }

      return '';
    },
  },
};
</script>
