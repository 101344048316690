<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <modal @close="$emit('close', true)">
      <template v-slot:title>
        Reagendar Consulta
      </template>

      <template v-slot:body>
        <section class="appointment-date pt-8">
          <div class="appointment-date__box">
            <h2 class="appointment-date__title">
              Escolha abaixo uma data
            </h2>

            <div class="appointment-date__wrapper">
              <div
                :class="{ 'appointment-date__date--disabled': ui.isLoading }"
                class="appointment-date__date"
                @click="openSafariDatepicker"
              >
                <span class="appointment-date__date-text">
                  {{ appointmentDate | formattedDate }}
                </span>

                <img
                  :src="require(`@/assets/img/icons/${partner.theme}-ico-pickdate.svg`)"
                  class="appointment-date__date-icon"
                >
              </div>

              <input
                v-if="!isSafariDesktop"
                v-model="appointmentDate"
                :disabled="ui.isLoading"
                :min="today"
                type="date"
                class="appointment-date__date-input"
              >

              <div
                v-show="isSafariDesktop && ui.toggleSafariDatepicker"
                class="appointment-date__safari-datepicker"
              >
                <dr-calendar v-model="appointmentDateSafari" />
              </div>
            </div>
          </div>

          <div class="appointment-date__box mb-0">
            <h2 class="appointment-date__title">
              Escolha um horário
            </h2>

            <div class="appointment-date__time">
              <transition name="blend">
                <div
                  v-if="ui.isLoading"
                  :key="'loader'"
                  class="appointment-date__loader"
                >
                  <div class="appointment-date__loader-wrapper">
                    <p class="appointment-date__loader-text">
                      Carregando horários disponíveis, aguarde...
                    </p>

                    <progress class="appointment-date__loader-bar" />
                  </div>
                </div>
              </transition>

              <transition name="blend">
                <div
                  v-if="!ui.isLoading && timetable.length > 0"
                  class="appointment-date__time-wrapper"
                >
                  <list-filter
                    v-model="appointmentTime"
                    :selected="appointmentTime"
                    :disabled.sync="ui.isLoading"
                    :bigger-label="true"
                    :options.sync="timetable"
                    class="appointment-date__time-list"
                    style="max-height: 23vh; overflow-y: auto;"
                  />
                </div>
              </transition>

              <transition name="blend">
                <div
                  v-if="!ui.isLoading && ui.notFound"
                  class="appointment-date__time-wrapper"
                >
                  <p class="appointment-date__time-not-found">
                    Não foram encontrados horários disponíveis na data escolhida.
                  </p>
                </div>
              </transition>
            </div>
          </div>

          <div class="appointment-date__call-to-action">
            <ui-button
              :disabled="ui.isLoading || !isValid"
              label="Reagendar consulta"
              @click="saveReschedule"
            />
          </div>
        </section>

        <transition
          name="fade"
          mode="out-in"
        >
          <loader v-if="ui.isSending" />
        </transition>
      </template>
    </modal>
  </transition>
</template>

<script>
import {
  format,
  formatISO,
  parseISO,
  isBefore,
  addMinutes,
} from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { mapGetters, mapActions } from 'vuex';
import { DrCalendar } from 'cm2tech-vue-calendar';
import doctorAgenda from '@/api/doctor/doctorAgenda';
import appointments from '@/api/user/appointments';
import handleError from '@/mixins/handleError';
import Modal from '@/components/ui/Modal.vue';
import Loader from '@/components/ui/Loader.vue';
import UiButton from '@/components/ui/Button.vue';
import ListFilter from '@/components/ui/ListFilter.vue';

export default {
  name: 'UserAppointmentReschedule',

  mixins: [handleError],

  filters: {
    formattedDate(value) {
      const args = value.split('-');

      return format(
        new Date(
          parseInt(args[0], 10),
          parseInt(args[1], 10) - 1,
          parseInt(args[2], 10),
        ),
        'iiii\',\' d \'de\' MMMM \'de\' yyyy',
        { locale: ptBr },
      ).toLowerCase();
    },
  },

  components: {
    DrCalendar,
    ListFilter,
    UiButton,
    Loader,
    Modal,
  },

  props: {
    officeId: {
      type: Number,
      required: true,
    },

    modality: {
      type: String,
      required: true,
      validator: (val) => ['TELEMEDICINE', 'FACETOFACE'].includes(val),
    },

    agendaId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      today: null,
      timetable: [],
      appointmentDate: null,
      appointmentTime: null,
      appointmentDateSafari: null,
      agenda: {
        api: null,
      },
      ui: {
        isLoading: false,
        isSending: false,
        toggleSafariDatepicker: false,
      },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),

    ...mapGetters('user', ['headers']),

    isSafariDesktop() {
      const agent = navigator.userAgent.toLowerCase();

      return /safari/gi.test(agent) && !/iphone/gi.test(agent);
    },

    isValid() {
      return !!this.appointmentTime;
    },
  },

  watch: {
    appointmentDate: {
      deep: true,
      immediate: false,
      handler(value, oldValue) {
        const [year, month, day] = value.split('-');
        const date = new Date(year, month - 1, day);

        if (isBefore(date, new Date())) {
          this.appointmentDate = format(new Date(), 'yyyy-MM-dd');
        }

        if (this.appointmentDate !== oldValue) {
          this.getAvailableTime();
        }
      },
    },

    'appointmentTime.time': function (next) {
      if (!next) {
        return;
      }
      const now = parseInt(format(addMinutes(new Date(), 15), 'yyyyMMddHHmm'), 10);
      const target = parseInt(format(new Date(`${this.appointmentDate}T${next}`), 'yyyyMMddHHmm'), 10);
      if (now > target) {
        this.handleError('Horário indisponível');
        this.$nextTick(() => {
          this.appointmentTime = null;
        });
      }
    },

    appointmentDateSafari: {
      deep: false,
      immediate: false,
      handler(next, prev) {
        if (next !== prev) {
          this.ui.toggleSafariDatepicker = false;
          const then = parseISO(next);

          if (isBefore(then, new Date())) {
            this.appointmentDateSafari = formatISO(new Date());
          }

          this.appointmentDate = format(parseISO(this.appointmentDateSafari), 'yyyy-MM-dd');
        }
      },
    },
  },

  created() {
    this.today = format(new Date(), 'yyyy-MM-dd');
    this.appointmentDate = this.today;
    this.appointmentDateSafari = formatISO(new Date());

    this.agenda.api = doctorAgenda(this.headers);

    this.getAvailableTime();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),

    openSafariDatepicker() {
      if (this.isSafariDesktop) {
        this.ui.toggleSafariDatepicker = !this.ui.toggleSafariDatepicker;
      }
    },

    getAvailableTime() {
      this.appointmentTime = null;
      this.timetable = [];
      this.ui.isLoading = true;
      this.ui.notFound = false;

      this.agenda.api.fetchOfficeSchedule(this.officeId, this.appointmentDate, this.modality)
        .then((res) => {
          this.timetable = res;

          if (this.timetable.length === 0) {
            this.ui.notFound = true;
          }
        })
        .catch(() => {
          this.ui.notFound = true;
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    saveReschedule() {
      const reschedule = appointments(this.headers, this.agendaId);
      const data = `${this.appointmentDate} ${this.appointmentTime.time}`;

      this.ui.isSending = true;
      this.toggleProgressBar();

      reschedule.reschedule(data)
        .then((res) => {
          const { id } = res;
          this.$emit('close', true);

          this.openModalDialog({
            type: 'alert',
            title: 'Sucesso',
            text: 'A data da consulta for alterada com sucesso.',
            confirmText: 'Fechar',
            size: 'sm',
            fn: () => {
              this.closeModalDialog();
              this.$router.push({ name: 'UserAppointmentDetails', params: { id } });
            },
          });
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.ui.isSending = false;
          this.toggleProgressBar(false);
        });
    },
  },
};
</script>

<style lang="scss">
.blend {
  &-enter {
    opacity: 0;
    display: none;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 1;
      position: relative;
      transition: all 0.3s ease;
      transition-delay: 100ms;
    }
  }

  &-leave {
    display: none;
    opacity: 1;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
</style>
